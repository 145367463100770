<template>
  <el-dialog title="添加商品" v-model="dialogVisible" width="70%"   >
    <el-row>
      <el-col :span="8">
        <div>
          <el-input v-model="filterText" placeholder="请输入关键字" style="width:100%;margin-bottom: 10px;" size="mini"></el-input>
          <el-tree ref="tree" :filter-node-method="filterNode" :data="data" :props="defaultProps" @node-click="handleNodeClick" @check="handleCheckChangeArea" node-key="id" style="width:100%;height:354px;overflow:scroll"></el-tree>
        </div>
      </el-col>
      <el-col :span="16">
        <div>
          
          <el-form ref="ruleForm" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-row>
              <el-form-item label="公司列表：" style="width:50%">
                <el-input v-model="company" disabled></el-input>
              </el-form-item>
              <el-form-item label="税收分类编码：" style="width:50%" prop="short_code">
              <el-input v-model="form.short_code" disabled></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="商品名称：" style="width:50%" prop="name">
              <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="税收分类简称：" style="width:50%">
                <el-input v-model="form.name2" disabled></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <!-- <el-form-item label="商品简码：" style="width:50%">
                <el-input v-model="form.name2"></el-input>
              </el-form-item> -->
              <el-form-item label="规格型号：" style="width:50%">
                <el-input v-model="form.spec"></el-input>
              </el-form-item>
              <el-form-item label="单价：" style="width:50%">
                <el-input v-model="form.price"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="单位：" style="width:50%">
                <el-input v-model="form.unit"></el-input>
              </el-form-item>
              <el-form-item label="商品税率：" style="width:50%">
                <el-select :disabled="form.rateDisaible" v-model="form.rate" placeholder="请选择" clearable>
                  <el-option v-for="item in rateOptions" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            
            <el-row>
              <el-form-item label="含税标志：" style="width:50%">
                <el-radio-group v-model="form.hsbz">
                  <el-radio label="1">含税</el-radio>
                  <el-radio label="0">不含税</el-radio>
                </el-radio-group>
                </el-form-item>
                <el-form-item label="免税类型：" style="width:50%">
                <el-select @change="changeMslx" v-model="form.mslx" placeholder="请选择" clearable>
                  <el-option v-for="item in mslxOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="默认商品：" style="width:50%">
                  <el-radio-group v-model="form.mrsp">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              
              <el-form-item label="使用优惠政策：" style="width:50%">
                <el-radio-group v-model="form.yh_status">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="优惠政策类型：" style="width:50%">
                <el-select :disabled="this.form.yh_status == '0'" v-model="form.yh_text" placeholder="请选择" clearable>
                  <el-option v-for="item in yhzclxOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="税率：" style="width:50%">
                <el-select v-model="form.sl" placeholder="请选择" clearable>
                  <el-option v-for="item in slOptions" :key="item.value" :label="item.sl" :value="item.sl"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button :loading="loading" type="success" @click="znfmClick()" size="mini" plain>智能赋码</el-button>
        <el-button @click="this.dialogVisible = false" size="mini">关 闭</el-button>
        <el-button @click="resetForm()" size="mini">重 置</el-button>
        <el-button type="primary" @click="add" size="mini">保 存</el-button>
      </span>
    </template>
  </el-dialog>


  <!-- 智能赋码弹框 -->
  <el-dialog v-model="dialogTableVisible" width="700px" title="智能赋码">
    <el-table :data="fmData" border>
      <el-table-column
        property="name"
        label="名称"
        width="200"
      ></el-table-column>
      <el-table-column
        property="code"
        label="编码"
        width="200"
      ></el-table-column>
       <el-table-column
        property="bl"
        label="使用比例"
        width="100"
      ></el-table-column>
      <el-table-column>
        <template v-slot="scope">
          <el-button type="text" size="small" @click="shiyong(scope.row)">使用</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>


</template>

<script>
//  <add-goods v-model="dialogVisible"></add-goods>
import { getSzsmxx ,inventoryClass,addGood,getBaseDict, znfm } from "@/api/ea";
export default {
  name:'addGoods',
  props: {
      row:{}
  },
  created(){
    this.getSzsmxxList()
    this.getInventoryClass()
    this.getswlx()
  },
  data(){
    return {
      loading: false,
      fmData: [],
      dialogTableVisible: false,
      filterText: '',
      company: this.$store.getters['user/comInfo'].name,
      form: {
        short_code: '',
        name: '',
        name2:'',
        short_name: '',
        spjm:'',
        spec:'',
        price:'',
        unit:'',
        yh_status:'0',
        yh_text:'',
        sl:'',
        mslx:'',
        hsbz:'0',
        mrsp:'0',
        rateDisaible: false,
      },
      slOptions:[],
      rateOptions: [
        "0.17",
        "0.16",
        "0.13",
        "0.10",
        "0.09",
        "0.06",
        "0.05",
        "0.03",
        "0.02",
        "0.01",
        "0.015",
        "0",
      ],
      yhzclxOptions:[],
      mslxOptions:[],
      data:[],
      defaultProps: {
         children: 'children',
         label: 'name',
      },
      dialogVisible:false,
      rules:{
        name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ],
        short_code: [
          { required: true, message: '请选择免税类型', trigger: 'blur' },
        ],
      },
      listQuery:{
          id:'',
          fplxdm:'004'
      },
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods:{
    changeMslx(){
      if(this.form.mslx != ""){
        this.form.rate = "0"
        this.form.rateDisaible = true
      } else {
        this.form.rateDisaible = false
      }
    },
    shiyong(row){
      this.form.short_code = row.code
      this.form.name2 = row.name
      this.dialogTableVisible = false
    },
    znfmClick(){
      if(!this.form.name){
        this.$qzfMessage("请输入商品名称",1)
      }
      this.loading = true
      znfm({name: this.form.name}).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          console.log(res.data.data.list)
          this.fmData = []
          res.data.data.list.map(v=>{
            this.fmData.push({
              "name": v.split('--')[0],
              "code": v.split('--')[1],
              "bl": v.split('--')[2]
            })
            this.dialogTableVisible = true
          })
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    //获取税种税目信息(拿税率)
    getSzsmxxList(){
      getSzsmxx({id:this.$store.getters['user/comInfo'].id*1,fplxdm:'004'}).then(res=>{
        this.slOptions = res.data.data.list.filter((item)=>{
          if(item.hsbz == "0"){
            return true
          }
        })
      })
    },
    //获取税收分类编码（树状图的）
    getInventoryClass(){
      inventoryClass({}).then(res=>{
        this.data = res.data.data.list
      })
    },
    getswlx(){
      getBaseDict({type:1}).then(res=>{
        this.yhzclxOptions = res.data.data.list
      })
      getBaseDict({type:2}).then(res=>{
        this.mslxOptions = res.data.data.list
      })
    },
    add(){
      this.form.comId = this.$store.getters['user/comInfo'].id
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          addGood(this.form).then(res=>{
            if(res.data.msg == 'success'){
              if(!this.form.id){
                this.$qzfMessage("新增成功", 3)
              }else{
                this.$qzfMessage("修改成功", 3)
              }
              this.$emit("success", res.data.data.info)
              this.dialogVisible = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleNodeClick(data){
      if(!data.children){
        this.form.short_code = data.code
        this.form.name2 = data.short_name
      }
    },
    handleCheckChangeArea(data){
      this.form.short_code = data.code
      this.form.name2 = data.short_name
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields()
    },
  }
}
</script>

<style lang="scss" scoped>
.form_style{
    position: absolute;
    top: 37px;
    right: 73px;
    width: 60%;
}
.el-dialog__footer{
    position: absolute; 
    bottom: 14px;
    right: 13px;
}
.right{
  text-align: right;
  padding: 10px 10px 10px 10px;
}
.left{
  position: absolute;
  top: 110px;
  left: 214px;
}
 
   .el-tree > .el-tree-node > .el-tree-node__content .el-checkbox {
  display: none;
}
.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
</style>