<template>
  <el-date-picker
    clearable
    value-format="YYYYMMDD"
    @change="changeTime"
    size="mini"
    v-model="times"
    type="daterange"
    range-separator="到"
    start-placeholder="开始时间"
    end-placeholder="结束时间"
  >
  </el-date-picker>
</template>
<script>
// <invoice-type v-model:code="temp.fplx"></invoice-type>
import { getCorpList } from "@/api/ea";
export default {
  name: 'timeSection',
  props: {
    startTime: '',
    endTime: '',
  },
  data() {
    return {
      times: []
    }
  },
  created () {
    
  },
  methods: {
    changeTime(){
      if(this.times && this.times.length == 2){
        this.$emit('update:startTime', this.times[0])
        this.$emit('update:endTime', this.times[1])
      }else{
        this.$emit('update:startTime', "")
        this.$emit('update:endTime', "")
      }
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
