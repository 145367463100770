<template>
  <div class="app-main">
    <router-view :key="key" v-slot="{ Component }">
      <!-- 缓存页面 3.0写法 -->
      <keep-alive :include="cachedViews">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
<script>
export default {
  name: 'right',
  data () {
    return {
      sss: ["index1"]
    }
  },
  computed: {
    cachedViews() {
      return this.$store.getters['tagsView/cachedViews']
    },
    // key() {
    //   return this.$route.fullPath
    // }
  },
  setup() {
    
  },
}
</script>

<style lang="scss" scoped>
.app-main{
  width: calc(100% - 20px);
  height: calc(100vh - 100px);
  // margin: 10px;
  background: #fff;
  margin-top: 5px;
  margin-left: 5px;
  overflow: auto;
}
</style>