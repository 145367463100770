<template>
  <el-dialog title="购方纳税人" v-model="dialogVisible" width="30%">
    <el-form ref="form" :model="form" label-width="120px" class="form_style" size="mini" label-position="right" :rules="rules">
      <el-form-item label="纳税人名称：" style="width:90%" prop="name">
       <!-- <el-input v-model="form.name"></el-input> -->
       <el-autocomplete v-model="form.name" :fetch-suggestions="querySearchAsync" @select="handleSelect" style="width:100%"/>
      </el-form-item>
      <el-form-item label="纳税人识别号：" style="width:90%" prop="tax_no">
       <el-input v-model="form.tax_no"></el-input>
      </el-form-item>
      <el-form-item label="拼音/简码：" style="width:90%">
        <el-input v-model="form.short_code"></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否公用：" style="width:90%">
        <el-input v-model="form.name"></el-input>
      </el-form-item> --> 
      <el-form-item label="地址：" style="width:90%">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="电话：" style="width:90%">
        <el-input v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item label="开户行名称：" style="width:90%">
        <el-input v-model="form.khhmc"></el-input>
      </el-form-item>
      <el-form-item label="开户行账号：" style="width:90%">
        <el-input v-model="form.khhzh"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" style="width:90%">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="所属企业识别号：" style="width:90%">
        <el-input v-model="form.comTax_no" disabled></el-input>
      </el-form-item>
      <el-form-item label="所属企业名称：" style="width:90%">
        <el-input v-model="form.companyName" disabled></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.dialogVisible = false" size="mini">关 闭</el-button>
        <el-button @click="resetForm('form')" size="mini">重 置</el-button>
        <el-button type="primary" @click="addCorpCustomers('form')" size="mini">保 存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { addCorpCustomer , collectionCorp } from "@/api/ea";
export default {
  name: 'buyer',
  data(){
    return {
      dialogVisible: false,
      form: {
        name: '',
        tax_no: '',
        address: '',
        tel: '',
        email:'',
        khhmc: '',
        khhzh: '',
        comTax_no:this.$store.getters['user/comInfo'].tax_no,
        companyName:this.$store.getters['user/comInfo'].name,
        comId:this.$store.getters['user/comInfo'].id
      },
      tableData: [],
      rules:{
        name: [
          { required: true, message: '请输入纳税人名称', trigger: 'blur' },
        ],
        // address: [
        //   { required: true, message: '请输入地址', trigger: 'blur' },
        // ],
        // tel: [
        //   { required: true, message: '请输入电话', trigger: 'blur' },
        // ],
        // khhmc: [
        //   { required: true, message: '请输入开户行名称', trigger: 'blur' },
        // ],
        // khhzh: [
        //   { required: true, message: '请输入开户行账号',  trigger: 'blur' },
        // ],
        tax_no: [
          { required: true, message: '请输入纳税人识别号',  trigger: 'blur' },
        ],
        // short_code: [
        //   { required: true, message: '请输入简码/拼音', trigger: 'blur' },
        // ],
      }
    }
  },
  methods:{
    addCorpCustomers(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addCorpCustomer([this.form]).then(res=>{
            if(res.data.msg == 'success'){
              if(!this.form.id){
                this.$qzfMessage("新增成功", 3)
              }else{
                this.$qzfMessage("修改成功", 3)
              }
              this.dialogVisible = false
              this.$emit("success", res.data.data.info)
            }else{
              this.$qzfMessage("新增失败", 2)
            }
          })
        } else {
          return false
        }
      }) 
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //input输入框
    querySearchAsync(queryString, cb) {
      if(queryString == ""){
        return
      }
      collectionCorp({name:queryString}).then(res=>{
        let arr = []
        res.data.data.list.map(v=>{
          console.log(v.ghdwmc);
          console.log(v.ghdwsbh);
          arr.push({
            value: v.ghdwmc,
            tax_no: v.ghdwsbh,
            yhzh:v.ghdwyhzh,
            dzdh:v.ghdwdzdh
          })
        })
        console.log(arr);
        cb(arr)
      })
    },
    handleSelect(item) {
      this.form.tax_no = item.tax_no
      this.form.address = item.dzdh
      this.form.khhmc = item.yhzh
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  text-align: right;
  padding: 10px 10px 10px 10px;
}
 
</style>