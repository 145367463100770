<template>
  <div class="header-big clearfix"> 
    <img class="logo" src="@/assets/logo.svg" alt="">
    <span v-if="!comId" style="display:inline-block;border-left:1px solid #dbdcdd;height: 35px;width: 20px;position: relative;top: 8px;left: -16px;"></span>
    <span v-if="!comId" style="display:inline-block;position:relative;left:-18px;top:-3px">鲸算盘后台监控运维平台</span>
    
    <!-- <span style="color:#409eff;font-size: 34px;">信息维护</span> -->
    <div class="right_header clearfix">
      <div class="header-name">
        <i class="iconfont icon-gongsi"></i>
        <span>{{orgName}}</span>
        <i class="el-icon-user"></i>
        <span>{{cnName}}</span>
      </div>
      <div @click="logout()" class="header-goback">
        <i class="iconfont icon-tuichu"></i>
        {{ $t('header_menu.logout') }}
    </div>
    </div>
  </div>
</template>
<script>
import { companyList, userSaveComId } from "@/api/ea";
export default {
  name: "header",
  data () {
    return {
      dialogFormVisible: false,
      companyId: {},
      companys: [],
      companysSearch: [],
      filterText: "",
      cnName:this.$store.getters['user/user'].cnName,
      orgName:this.$store.getters['user/user'].orgName
    }
  },
  created(){
    let nowTime = new Date().getTime()
    let timeDiff = Number(nowTime) - Number(localStorage.getItem('time'))
    if (timeDiff < 5000) { // 5000
      this.dialogFormVisible = true
    }
    this.getList()
  },
  watch: {
    filterText(val) {
      this.companysSearch = this.companys.filter(v=>{return v.name.indexOf(this.filterText) > -1})
    }
  },
  methods: {
    handleClose(done) {
      // done();
    },
    updataCom(row){
      this.companyId = row.id
      this.changeCompany(2)
      this.dialogFormVisible = false
    },
    closeAllTags() {
      this.$store.dispatch('tagsView/delAllViews')
      this.$router.push('/')
    },
    changeCompany(type){ 
      let company = this.companys.filter(v=>{return this.companyId == v.id})
      if(company[0]){
        this.$store.dispatch('user/setComInfo', company[0])
        userSaveComId({thisComId: this.companyId}).then(res=>{
          if(res.data.data.returnMsg){
            this.$qzfMessage(res.data.data.returnMsg, 2)
          }
        })
        if(type == 2){
          this.closeAllTags()
        }
      }
    },
    getList(){
      // companyList({}).then(res=>{
      //   if(res.data.msg == "success"){
      //     this.companys = res.data.data.list
      //     this.companysSearch = res.data.data.list
      //     if(this.companys.length != 0){
      //       this.companyId = this.companys[0].id
      //       if(this.$store.getters['user/user'].thisComId){
      //         this.companyId = this.$store.getters['user/user'].thisComId
      //       }
      //       this.changeCompany(1)
      //     }
      //   }
      // })
    },
    logout(){
      this.$store.dispatch('user/LogOut').then(() => {
        location.reload()
      })
    }
  },
  setup() {
    
  },
}
</script>

<style>
.company-span .el-select .el-input__inner{
  border:0 !important;
  padding-right: 30px;
}
.company-span .el-input__inner{
  padding: 0 6px !important;
}
.company-span .el-input__inner{
  position: relative;
  top: -3px;
}
.company-span .el-input__suffix {
  top: -2px;
}
</style>
<style lang="scss" scoped>
.header-big{
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 30px;
  background:white;
  border-bottom: 1px solid #eee;
  .logo{
    position: relative;
    // top: 3px;
    left: -20px;
    margin-top: 0px;
    float: left;
    width: 136px;
    height: 50px;
    font-size: 14px;
    // margin-left: 7px;
  }
  .right_header{
    float: right;
    padding-top: 10px;
  }
  .header-name{
    // position: absolute;
    // right: 100px;
    // top: 15px;
    margin-top: 4px;
    margin-right: 9px;
    float: left;
    i{
      vertical-align: top;
    }
    span{
      vertical-align: top;
      font-size: 13px;
      margin-left: 5px;
      margin-right: 7px;
    }
  }
  .header-goback{
    // position: absolute;
    // top: 18px;
    // right: 20px;
    float: left;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    line-height: 28px;
  }
}
.company-span{
  height: 30px;
  i{
    line-height: 30px;
    color: #666;
  }
}
.company{
  min-width: 300px;
  height: 30px;
}
.el-icon-printer{
  vertical-align: sub;
}
</style>