import { loginByUsername, logout, getUserInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
export const user = {
  namespaced: true,
  state: {
    token: '',
    user: {

    },
    comInfo:{
      id: 0,
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_COMINFO: (state, comInfo) => {
      state.comInfo = comInfo
    },
    SET_USER: (state, user) => {
      state.user = user
    },
  },
  actions: {
    setComInfo({ commit }, comInfo) {
      commit('SET_COMINFO', comInfo)
    },
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    // 用户名登录
    LoginByUsername({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        loginByUsername(username, password).then(response => {
          const isDev = window.location.hostname=='localhost' || window.location.hostname.indexOf('-dev') > -1;
          let token = '';
          if (isDev) {
            token = response.headers['token'] // header里的属性都会转成小写才能获取
          } else {
            token = response.headers['token']//Cookies.get('Token') // 后端需设置httpOnly为false,否则https模式获取不到cookies
          }
          if (token) {
            commit('SET_TOKEN', token)
            setToken(token)
            resolve()
          } else {
            reject(response.data.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        //state.token
        getUserInfo({}).then(response => {
          if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
            reject('error')
          }
          const data = response.data.data
          commit('SET_COMINFO', data.company)
          commit('SET_USER', data.user)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {
    token(state) {
      return state.token
    },
    comInfo(state) {
      return state.comInfo
    },
    user(state) {
      return state.user
    },
  }
}
