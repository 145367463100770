import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import locale from "element-plus/lib/locale/lang/zh-cn";
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import VueI18n from './language'
import 'element-plus/dist/index.css'
import './style/element.scss'

import './assets/css/all.css'
import './assets/icon/iconfont.css'
import '@/permission'

import component from '@/utils/component';//注册全局组件
// import fun from '@/utils/fun';//注册全局组件

const app = createApp(App)
app.config.globalProperties.$echarts = echarts

import { copy, message, fplx ,getHeight,currentAccountPeriod ,parseTime } from '@/utils/fun'

// 过滤
import { taskNameType,setUpStatusGsSb,batchSendSb,cityFilter } from '@/utils/filter'

app.mixin({
  methods: {
    $qzfMessage: message,
    $qzfCopy: copy,
    $fplx: fplx,
    $getHeight: getHeight,
    $currentAccountPeriod:currentAccountPeriod,
    $parseTime:parseTime,

    // 过滤
    $taskNameType: taskNameType,
    $setUpStatusGsSb:setUpStatusGsSb,
    $batchSendSb:batchSendSb,
    $cityFilter:cityFilter
  }
})

/**
 * 检测图片是否存在
 * @param url
 */
 let imageIsExist = function(url) {
  return new Promise((resolve) => {
      var img = new Image();
      img.onload = function () {
          if (this.complete == true){
              resolve(true);
              img = null;
          }
      }
      img.onerror = function () {
          resolve(false);
          img = null;
      }
      img.src = url;
  })
}
app.directive('err-img', async function (el, binding) {//指令名称为：real-img
  let imgURL = binding.value;//获取图片地址
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
          el.setAttribute('src', imgURL);
      } 
  }
})

app.use(component).use(VueI18n).use(ElementPlus,{locale}).use(store).use(router).mount('#app')