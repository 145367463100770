<template>
  <div class="tags-view-container">
    <scroll-pane class="tags-view-wrapper">
      <el-tag class="tag"
        @contextmenu.prevent.native="openMenu(tag,$event)"
        v-for="tag in visitedViews" 
        :class="isActive(tag)?'cur':''" 
        @click="goView(tag)"  
        @close="closeSelectedTag(tag)" 
        :key="tag.name" 
        closable 
        effect="plain" 
        :type="tag.type">
        {{ tag.meta.title }}
      </el-tag>
    </scroll-pane>
    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li @click="refreshSelectedTag(selectedTag)">刷新</li>
      <li @click="closeSelectedTag(selectedTag)">关闭</li>
      <!-- <li @click="closeOthersTags">关闭其他</li> -->
      <li @click="closeAllTags">关闭所有</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from '@/components/ScrollPane'
import { generateTitle } from '@/utils/i18n'

export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    }
  },
  computed: {
    visitedViews() {
      return this.$store.getters['tagsView/visitedViews']
    }
  },
  watch: {
    $route() {
      this.addViewTags(1)
      // this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    this.addViewTags(2)
  },
  methods: {
    generateTitle,
    isActive(route) {
      return route.path === this.$route.path
    },
    goView(tag){
      this.$router.push({
        path: tag.path,
      });
    },
    addViewTags(type) {
      const { name } = this.$route
      if (name && !this.$route.meta.noCache && type == 1) {
        this.$store.dispatch('tagsView/addView', this.$route)
      }
      if(name && !this.$route.meta.noCache && type == 2){
        this.$store.dispatch('tagsView/addVisitedView', this.$route)
      }
      return false
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)

            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.$route)
            }

            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      this.$store.dispatch('tagsView/delCachedView', view).then(() => {
        const { fullPath } = view
        console.log('fullpath:', fullPath)
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirects',
            query: {
              path: fullPath,
            },
          })
        })
      })
    },
    closeSelectedTag(view) {
      this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          let latestView = visitedViews.slice(-1)[0]
          let fromView = localStorage.getItem("fromView");
          visitedViews.map(v=>{
            if(v.path == fromView){
              latestView = v
            }
          })
          if (latestView) {
            this.$router.push(latestView)
          } else {
            this.$router.push('/')
          }
        }
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag)
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags() {
      this.$store.dispatch('tagsView/delAllViews')
      this.$router.push('/')
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 205 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }
      this.top = e.clientY

      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tag{
  cursor: pointer;
}
.scroll-container{
  height: auto !important;
}
.tags-view-container .tags-view-wrapper .tags-view-item:first-of-type{
  text-align: left !important;
  margin-left: 0 !important;
  float: left;
}
.tags-view-container {
  // height: 40px;
  // background: #fff;
  //  white-space:nowrap;
  // height: 50px;
  // overflow: hidden;
  width: 100%;
  margin: 0 auto;
  text-align: left !important;
  padding: 0 5px;
  // transform: translateY(5px);
  margin-bottom: -5px;
  .tags-view-wrapper {
    .tag {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #e6e6e6;
      border-bottom:none;
      // border-right: 1px solid #d8dce5;
      margin-right:3px;
      color: #495060;
      background: #eee;
      padding: 0 8px;
      font-size: 12px;
      // margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left:0 !important;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.cur {
        background-color: #409eff;
        color: #fff;
        border-color: #409eff;
        // border-color: #42b983;
        &::before {
          content: '';
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 2147483647;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
.cur{
   background-color: #409eff;
   color: #fff;
   border-color: #409eff;
   &::before {
    content: '';
    background: #fff;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    margin-right: 2px;
   }
}
</style>

<style rel="stylesheet/scss" lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tag {
    .el-icon-close {
      width: 16px;
      height: 16px;
      // vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(.6);
        display: inline-block;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
.el-tag{
  text-align: left !important;
  // margin:5px 2px 3px 1px;
}
</style>
