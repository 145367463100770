<template>
  <div>
    <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick">
    <el-button :loading="loading" style="" size="mini" type="primary" @click="handleUpload">上传</el-button>
  </div>
</template>

<script>
// 录发票专用
import XLSX, { readFile } from "xlsx";

export default {
  props: {
    rawFile: "",
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function // eslint-disable-line
  },
  data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      }
    };
  },
  methods: {
    generateData(header, results ) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error("Only support uploading one file!");
        return;
      }
      const rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error(
          "Only supports upload .xlsx, .xls, .csv suffix files"
        );
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload() {
      // this.$refs["excel-upload-input"].click();
      this.upload(this.rawFile);
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let allHeaders = []
        let allResults = []
        reader.onload = e => {
          const data = e.target.result;
          // console.log(data)
          const fixedData = this.fixData(data);
          // console.log(fixedData)
          const workbook = XLSX.read(btoa(fixedData), { type: "base64" });
          // console.log(workbook)
          workbook.SheetNames.map(vv=>{
            const firstSheetName = vv;
            // console.log(firstSheetName)
            const worksheet = workbook.Sheets[firstSheetName];
            // console.log(worksheet)
            const header = this.getHeaderRow(worksheet);
            // console.log("header",header)
            const results = XLSX.utils.sheet_to_json(worksheet);
            
            allHeaders.push(header)
            allResults.push(results)
          })
          this.generateData(allHeaders, allResults);
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    fixData(data) {
      let o = "";
      let l = 0;
      const w = 10240;
      for (; l < data.byteLength / w; ++l)
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      // console.log(range)
      let C;
      // const R = range.s.r
      let R = range.s.r;
      for (R = range.s.r; R <= range.e.r; ++R) {
        /* start in the first row */
        //  console.log(R)
        let arr = [];
        for (C = range.s.c; C <= range.e.c; ++C) {
          /* walk every column in the range */
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
          /* find the cell in the first row */
          let hdr = ""; //'UNKNOWN ' + C // <-- replace with your desired default
          if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
          arr.push(hdr);
        }
        headers.push(arr);
      }
      // console.log('----------------------')
      // console.log(headers)
      // console.log('----------------------')
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
</script>

<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
