
export const tagsView = {
  namespaced: true,
  state: {
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.some(v => v.path === view.path)) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (state.cachedViews.includes(view.name)) return
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name)
      }
    },

    DEL_VISITED_VIEW: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
    DEL_CACHED_VIEW: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews.splice(index, 1)
          break
        }
      }
    },
    DEL_VISITED_VIEW_BY_NAME: (state, viewName) => {
      for (const i of state.visitedViews) {
        if (i.name === viewName) {
          const index = state.visitedViews.indexOf(i)
          state.visitedViews.splice(index, 1)
          break
        }
      }
    },
    DEL_CACHED_VIEW_BY_NAME: (state, viewName) => {
      for (const i of state.cachedViews) {
        if (i === viewName) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews.splice(index, 1)
          break
        }
      }
    },

    DEL_OTHERS_VISITED_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews = state.visitedViews.slice(i, i + 1)
          break
        }
      }
    },
    DEL_OTHERS_CACHED_VIEWS: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews = state.cachedViews.slice(index, index + 1)
          break
        }
      }
    },

    DEL_ALL_VISITED_VIEWS: state => {
      state.visitedViews = []
    },
    DEL_ALL_CACHED_VIEWS: state => {
      state.cachedViews = []
    },

    UPDATE_VISITED_VIEW: (state, view) => {
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
    }
  },
  actions: {
    addView({ dispatch }, view) {
      dispatch('addVisitedView', view)
      dispatch('addCachedView', view)
    },
    //新增tag页面缓存
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view)
    },
    //新增缓存
    addCachedView({ commit }, view) {
      commit('ADD_CACHED_VIEW', view)
    },
    //关闭页面
    delView({ dispatch, state }, view) {
      console.log(view)
      return new Promise(resolve => {
        dispatch('delVisitedView', view)
        dispatch('delCachedView', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delVisitedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEW', view)
        resolve([...state.visitedViews])
      })
    },
    delCachedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_CACHED_VIEW', view)
        resolve([...state.cachedViews])
      })
    },
    // 通过路由组件名称关闭页面, 使用场景: 列表A页面已经打开, 点击新增或修改页面, 打开B页面, B页面保存完成后, 自动关闭B页面
    delViewByName({ dispatch, state }, viewName) {
      return new Promise(resolve => {
        dispatch('delVisitedViewByName', viewName)
        dispatch('delCachedViewByName', viewName)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    // 通过路由组件名称刷新缓存, 使用场景: A页面已经打开, B页面的操作影响A页面的数据, B页面操作完手动调用此方法删除A页面缓存, 点击A页面标签或菜单时会重新加载数据
    delCachedViewByName({ commit, state }, viewName) {
      // console.log('to clear cache: ', viewName)
      return new Promise(resolve => {
        commit('DEL_CACHED_VIEW_BY_NAME', viewName)
        resolve([...state.cachedViews])
      })
    },
    // 通过路由组件名称删除Visited View
    delVisitedViewByName({ commit, state }, viewName) {
      // console.log('to deleted visited: ', viewName)
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEW_BY_NAME', viewName)
        resolve([...state.visitedViews])
      })
    },
    delAllViews({ dispatch, state }, view) {
      return new Promise(resolve => {
        dispatch('delAllVisitedViews', view)
        dispatch('delAllCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delAllVisitedViews({ commit, state }) {
      return new Promise(resolve => {
        commit('DEL_ALL_VISITED_VIEWS')
        resolve([...state.visitedViews])
      })
    },
    delAllCachedViews({ commit, state }) {
      return new Promise(resolve => {
        commit('DEL_ALL_CACHED_VIEWS')
        resolve([...state.cachedViews])
      })
    },

    delOthersViews({ dispatch, state }, view) {
      return new Promise(resolve => {
        dispatch('delOthersVisitedViews', view)
        dispatch('delOthersCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delOthersVisitedViews({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_VISITED_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
    delOthersCachedViews({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_CACHED_VIEWS', view)
        resolve([...state.cachedViews])
      })
    },
  },
  getters: {
    visitedViews(state) {
      return state.visitedViews
    },
    cachedViews(state) {
      return state.cachedViews
    }
  }
}
