// 电子税局任务类型
export function taskNameType(type){
  if(type == 'tax-sb-smallVat'){
    return "申报-小规模增值税纳税人"
  }else if(type == 'tax-sb-vat'){
    return "申报-增值税一般纳税人"
  }else if(type == 'tax-sb-taxQuarter'){
    return "申报-企业所得税申报表"
  }else if(type == 'tax-sb-deed'){
    return "申报-行为税"
  }else if(type == 'tax-sb-qtsr'){
    return "申报-其他收入"
  }else if(type == 'tax-sb-sl'){
    return "申报-水利建设专项收入"
  }else if(type == 'tax-sb-whsyjsf'){
    return "申报-文化事业建设费"
  }else if(type == 'tax-sb-cbj'){
    return "申报-残疾人保障金"
  }else if(type == 'tax-sb-xfs'){
    return "申报-消费税"
  }else if(type == 'tax-sb-ljcl'){
    return "申报-城市生活垃圾处置费"
  }else if(type == 'tax-sb-shebao'){
    return "申报-社保申报"
  }else if(type == 'tax-sb-cwbb'){
    return "申报-财务报表"
  }else if(type == 'tax-cwyear-sb'){
    return "申报-财务报表(年)"
  }else if(type == 'tax-year-sb'){
    return "申报-汇算清缴"
  }

  else if(type == 'tax-cj-jianzhang'){
    return "采集-建账采集(企业信息和税种税表)"
  }else if(type == 'tax-cj-jump'){
    return "采集-跳过采集"
  }else if(type == 'tax-cj-taxCategory'){
    return "采集-更新税种"
  }else if(type == 'tax-cj-resetTaxs'){
    return "采集-重新采集税表"
  }else if(type == 'tax-cj-outInvoice'){
    return "采集-销项发票"
  }else if(type == 'tax-cj-inInvoice'){
    return "采集-进项发票"
  }else if(type == 'tax-cj-invoice'){
    return "采集-进项销项发票"
  }else if(type == 'tax-cj-shebao'){
    return "采集-社保采集"
  }else if(type == 'tax-year-cj'){
    return "采集-汇算清缴"
  }else if(type == 'tax-ybjc'){
    return "采集-一表集成"
  }

  else if(type == 'tax-kk-smallVat'){
    return "扣款-小规模增值税纳税人"
  }else if(type == 'tax-kk-vat'){
    return "扣款-增值税一般纳税人"
  }else if(type == 'tax-kk-taxQuarter'){
    return "扣款-企业所得税申报表"
  }else if(type == 'tax-kk-deed'){
    return "扣款-行为税"
  }else if(type == 'tax-kk-qtsr'){
    return "扣款-其他收入"
  }else if(type == 'tax-kk-sl'){
    return "扣款-水利建设专项收入"
  }else if(type == 'tax-kk-whsyjsf'){
    return "扣款-文化事业建设费"
  }else if(type == 'tax-kk-cbj'){
    return "扣款-残疾人保障金"
  }else if(type == 'tax-kk-xfs'){
    return "扣款-消费税"
  }else if(type == 'tax-kk-ljcl'){
    return "扣款-城市生活垃圾处置费"
  }else if(type == 'tax-kk-fjs'){
    return "扣款-附加税"
  }else if(type == 'tax-kk-shebao'){
    return "扣款-社保申报"
  }else if(type == 'tax-kk-cwbb'){
    return "扣款-财务报表"
  }

  else if(type == 'tax-jc-shenbao'){
    return "检查-检查"
  }else if(type == 'tax-jc-shebao'){
    return "检查-社保"
  }

  else if(type == 'his-cj-historyCollect'){
    return "采集-历史数据采集"
  }else if(type == 'other-cj-jianzhang'){
    return "建账采集"
  }

}

// 个税任务结果
export function setUpStatusGsSb(type){
  // if(type == '0'){
  //   return '未申报'
  // }else if(type == '1'){
  //   return '排队中'
  // }else 
  if(type == '2'){
    return '成功'
  }else if(type == '3'){
    return '申报超时失败'
  }else if(type == '4'){
    return '客户端未启动失败'
  }else if(type == '5'){
    return '申报异常'
  }else if(type == '6'){
    return '申报失败'
  }else if(type == '7'){
    return '申报成功，待缴款'
  }else if(type == '8'){
    return '自动检查中'
  }else if(type == '10'){
    return '申报成功，无需缴款'
  }else if(type == '11'){
    return '扣款中'
  }else if(type == '12'){
    return '扣款异常'
  }else if(type == '13'){
    return '申报成功，扣款成功'
  }else if(type == '14'){
    return '已申报过'
  }else if(type == '20'){
    return '申报成功'
  }
}

// 税种申报 申报任务结果状态
export function  batchSendSb(type){
  if(type == '2'){
    return '成功'
  }else if(type == '3'){
    return '失败'
  }else if(type == '4'){
    return '提交金三处理中'
  }else if(type == '5'){
    return '申报成功,待缴款'
  }else if(type == '6'){
    return '申报成功,已缴款'
  }else if(type == '20'){
    return '已申报过，无需扣款'
  }else if(type == '21'){
    return '己申报过，待缴款'
  }else if(type == '22'){
    return '已申报过，已缴款'
  }
}

// 城市筛选
export function  cityFilter(type){
  if(type == 'beijing'){
    return '北京'
  }else if(type == 'shandong'){
    return '山东'
  }else if(type == 'qingdao'){
    return '青岛'
  }else if(type == 'neimenggu'){
    return '内蒙古'
  }else if(type == 'shanxi'){
    return '山西'
  }else if(type == 'tianjin'){
    return '天津'
  }else if(type == 'sichuan'){
    return '四川'
  }else if(type == 'jiangsu'){
    return '江苏'
  }else if(type == 'hebei'){
    return '河北'
  }else if(type == 'xizang'){
    return '西藏'
  }else if(type == 'qinghai'){
    return '青海'
  }else if(type == 'henan'){
    return '河南'
  }else if(type == 'shaanxi'){
    return '陕西'
  }else if(type == 'hainan'){
    return '海南'
  }else if(type == 'liaoning'){
    return '辽宁'
  }else if(type == 'shanghai'){
    return '上海'
  }else if(type == 'hunan'){
    return '湖南'
  }else if(type == 'guangdong'){
    return '广东'
  }else if(type == 'shenzhen'){
    return '深圳'
  }else if(type == 'guangxi'){
    return '广西'
  }else if(type == 'chongqing'){
    return '重庆'
  }else if(type == 'yunnan'){
    return '云南'
  }else if(type == 'xinjiang'){
    return '新疆'
  }else if(type == 'anhui'){
    return '安徽'
  }else if(type == 'hubei'){
    return '湖北'
  }else if(type == 'guizhou'){
    return '贵州'
  }else if(type == 'dalian'){
    return '大连'
  }else if(type == 'ningbo'){
    return '宁波'
  }else if(type == 'xiamen'){
    return '厦门'
  }else if(type == 'ningxia'){
    return '宁夏'
  }else if(type == 'jiangxi'){
    return '江西'
  }else if(type == 'jilin'){
    return '吉林'
  }else if(type == 'heilongjiang'){
    return '黑龙江'
  }else if(type == 'zhejiang'){
    return '浙江'
  }else if(type == 'fujian'){
    return '福建'
  }else if(type == 'guizhou'){
    return '贵州'
  }else if(type == 'gansu'){
    return '甘肃'
  }
}
