import Layout from '@/views/layout/index'

// noCache 不缓存
// hidden 不在左侧菜单栏显示
const eaRouter = [
  {
    path: '/redirects',
    component: Layout,
    name: 'redirects',
    redirect: '/redirects/redirectsindex',
    hidden: true,
    meta: {
      title: '跳转页',
      noCache: true,
      show: true,
    },
    children: [
      {
        path: 'redirectsindex',
        component: () => import('@/views/redirect/index'),
        name: 'redirectsindex',
        meta: {
          title: '跳转页子叶',
          noCache: true,
          show: true,
        },
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/homeIndex',
    meta: {
      title: '首页',
      icon: 'icon-home'
    },
    children: [{
      path: 'homeIndex',
      component: () => import ('@/views/home/homeIndex'),
      name: 'homeIndex',
      meta: { title: '首页', icon: 'icon-home' },
    }]
  },
  {
    path: '/operateMonitor',
    name: 'operateMonitor',
    redirect: '/operateMonitor/accountCollect',
    component: Layout,
    meta: {
      title: '运维监控',
      icon: 'icon-creditcard'
    },
    children: [{
      path: 'accountCollect',
      component: () => import ('@/views/operateMonitor/accountCollect'),
      name: 'accountCollect',
      meta: { title: '建账采集', icon: 'fapiao' },
    },
    {
      path: 'invoiceCollect',
      component: () => import ('@/views/operateMonitor/invoiceCollect'),
      name: 'invoiceCollect',
      meta: { title: '发票采集', icon: 'fapiao' },
    },
    {
      path: 'taxDeclaration',
      component: () => import ('@/views/operateMonitor/taxDeclaration'),
      name: 'taxDeclaration',
      meta: { title: '税局申报', icon: 'fapiao' },
    },
    {
      path: 'personalTask',
      component: () => import ('@/views/operateMonitor/personalTask'),
      name: 'personalTask',
      meta: { title: '个税任务', icon: 'fapiao' },
    },
    {
      path: 'socialTask',
      component: () => import ('@/views/operateMonitor/socialTask'),
      name: 'socialTask',
      meta: { title: '检查', icon: 'fapiao' },
    },
    {
      path: 'finalSettlement',
      component: () => import ('@/views/operateMonitor/finalSettlement'),
      name: 'finalSettlement',
      meta: { title: '汇算清缴', icon: 'fapiao' },
    },
  ]
  },

  {
    path: '/informationStatistics',
    name: 'informationStatistics',
    redirect: '/informationStatistics/coopCustomers',
    component: Layout,
    meta: {
      title: '信息统计',
      icon: 'icon-creditcard'
    },
      children: [{
        path: 'coopCustomers',
        component: () => import ('@/views/informationStatistics/coopCustomers'),
        name: 'coopCustomers',
        meta: { title: '合作客户', icon: 'fapiao' },
      },
      {
        path: 'taskStatistics',
        component: () => import ('@/views/informationStatistics/taskStatistics'),
        name: 'taskStatistics',
        meta: { title: '任务统计', icon: 'fapiao' },
      },
    ]
  },
  {
    path: '/smsHeartbeat',
    name: 'smsHeartbeat',
    redirect: '/smsHeartbeat/smsHeartbeat',
    component: Layout,
    meta: {
      title: '验证码记录',
      icon: 'icon-creditcard'
    },
      children: [{
        path: 'smsHeartbeat',
        component: () => import ('@/views/smsHeartbeat/smsHeartbeat'),
        name: 'smsHeartbeat',
        meta: { title: '验证码记录', icon: 'fapiao' },
      },
    ]
  },
  // {
  //   path: '/taxBureau',
  //   name: 'taxBureau',
  //   redirect: '/taxBureau/businessManagementBur',
  //   component: Layout,
  //   meta: {
  //     title: '信息统计',
  //     icon: 'icon-creditcard'
  //   },
  //   children: [{
  //     path: 'businessManagementBur',
  //     component: () => import ('@/views/taxBureau/businessManagementBur'),
  //     name: 'businessManagementBur',
  //     meta: { title: '客户管理', icon: 'fapiao' },
  //   },
  //   {
  //     path: 'taskCameraBur',
  //     component: () => import ('@/views/taxBureau/taskCameraBur'),
  //     name: 'taskCameraBur',
  //     meta: { title: '任务监控', icon: 'fapiao' },
  //   },
  //   {
  //     path: 'queue',
  //     component: () => import ('@/views/taxBureau/queue'),
  //     name: 'queue',
  //     meta: { title: '队列', icon: 'fapiao' },
  //   },
  //   ]
  // },
  // {
  //   path: '/setting',
  //   name: 'setting',
  //   redirect: '/setting/userManage',
  //   component: Layout,
  //   meta: {
  //     title: '设置',
  //     icon: 'icon-kitchen'
  //   },
  //   children: [{
  //     path: 'userManage',
  //     component: () => import ('@/views/setting/userManage'),
  //     name: 'userManage',
  //     meta: { title: '用户管理', icon: 'fapiao' },
  //   },
    
  // ]
  // },
  
]

export default eaRouter