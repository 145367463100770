<template>
  <div>
    <!-- v-model:currentPage="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      layout="layout"
      :total="total" -->
    <el-pagination
      v-model:currentPage="currentPage"
      :page-sizes="[10, 20, 50, 100,500,1000]"
      :page-size="limit"
      layout="total,sizes, prev, pager, next"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      small
    >
    </el-pagination>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
export default {
    name:'Pagination',
    props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('update:page',this.currentPage);
      this.$emit('update:limit',val);
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('update:page',val);
      this.$emit('update:limit',this.pageSize);
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  /* padding: 32px 16px; */
}
.pagination-container.hidden {
  display: none;
}
</style>
